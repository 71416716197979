import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

class AppDevelopment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDevelopments: [
        {
          id: 0,
          title: "ScoopBox",
          desc: "News App",
          source: require("../../assets/images/app1.png"),
          href: "https://github.com/vizvarun/ScoopBox-News-App-ReactNative",
        },
        {
          id: 1,
          title: "Harmony",
          desc: "Social Media",
          source: require("../../assets/images/app2.png"),
          href: "https://github.com/vizvarun/harmony-music-app/",
        },
        {
          id: 2,
          title: "Odyssey",
          desc: "Travel App",
          source: require("../../assets/images/app3.png"),
          href: "https://github.com/vizvarun/odyssey-rn-app",
        },
        {
          id: 3,
          title: "Lumos UI",
          desc: "Lamp Store App",
          source: require("../../assets/images/ui5.png"),
        },
      ],
    };
  }
  render() {
    return (
      <MDBRow className="project-block">
        {this.state.appDevelopments.map((appDevelopment, id) => (
          <>
            {id % 2 === 0 ? (
              <MDBCol md="12" lg="6">
                <div className="project-n">
                  <div className="project-title" key={appDevelopment.id}>
                    <h2>{appDevelopment.title}</h2>
                    <h4>{appDevelopment.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={appDevelopment.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div className="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={appDevelopment.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 15 10"
                              fill="white"
                            >
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            ) : (
              <MDBCol md="12" lg="6">
                <div className="project-n-1">
                  <div className="project-title" key={appDevelopment.id}>
                    <h2>{appDevelopment.title}</h2>
                    <h4>{appDevelopment.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={appDevelopment.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div className="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={appDevelopment.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 15 10"
                              fill="white"
                            >
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            )}
          </>
        ))}
      </MDBRow>
    );
  }
}

export default AppDevelopment;
