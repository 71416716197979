import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

class WebDevelopment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webDevelopments: [
        {
          id: 0,
          title: "Proefficient",
          desc: "ERP System",
          source: require("../../assets/images/web3.png"),
          href: "https://proefficient.now.sh/admin/section1",
        },
        {
          id: 1,
          title: "Vizon",
          desc: "Multimedia Search Engine",
          source: require("../../assets/images/web2.png"),
          href: "https://vizvarun.github.io/tcfvison",
        },
        {
          id: 2,
          title: "Inceptra",
          desc: "Fest Website",
          source: require("../../assets/images/web4.png"),
          href: "https://vizvarun.github.io/inceptra",
        },
        {
          id: 3,
          title: "Winter of Code",
          desc: "Community Website",
          source: require("../../assets/images/web5.png"),
          href: "https://winterofcode.com",
        },
      ],
    };
  }
  render() {
    return (
      <MDBRow className="project-block">
        {this.state.webDevelopments.map((webDevelopment, id) => (
          <>
            {id % 2 === 0 ? (
              <MDBCol md="12" lg="6">
                <div className="project-n">
                  <div className="project-title" key={webDevelopment.id}>
                    <h2>{webDevelopment.title}</h2>
                    <h4>{webDevelopment.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={webDevelopment.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div className="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={webDevelopment.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 15 10"
                              fill="#fff"
                            >
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            ) : (
              <MDBCol md="12" lg="6">
                <div className="project-n-1">
                  <div className="project-title" key={webDevelopment.id}>
                    <h2>{webDevelopment.title}</h2>
                    <h4>{webDevelopment.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={webDevelopment.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div className="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={webDevelopment.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 15 10"
                              fill="white"
                            >
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            )}
          </>
        ))}
      </MDBRow>
    );
  }
}

export default WebDevelopment;
