import React from "react";
import "./contact.styles.scss";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBCardBody,
  MDBCard,
} from "mdbreact";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <section id="contact" className="contact">
          <div className="divider-block" />
          <MDBContainer>
            <MDBRow className="about-row">
              <MDBCol lg="12" className="skills-text">
                <h4 className="head-text inner-span">Let's work together</h4>
                <h2 className="main-head">Contact Me</h2>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <MDBRow className="contact-row">
          <MDBCol md="1" className="detailed" />

          <MDBCol md="5" className="detailed">
            <div className="contact-head">
              <h4 className="contact-head-h4">Mail :</h4>
              <h5 className="contact-head-h5">
                <a href="mailto:varunvishal10@gmail.com?Subject=Hello">
                  varunvishal10@gmail.com
                </a>
              </h5>
              <h4 className="contact-head-h4">Phone :</h4>
              <h5 className="contact-head-h5">
                <a href="tel:8271256678">+91 8271256678</a>
              </h5>
              <h4 className="contact-head-h4">Address :</h4>
              <h5 className="contact-head-h5">Sarjapur, Bangalore - 560035</h5>
            </div>
          </MDBCol>
          <MDBCol md="5">
            <MDBCard>
              <MDBCardBody>
                <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/xayplryb"
                  method="POST"
                >
                  <label
                    htmlFor="defaultFormContactNameEx"
                    className="grey-text"
                  >
                    Your name
                  </label>
                  <input
                    type="text"
                    id="defaultFormContactNameEx"
                    className="form-control"
                    name="name"
                  />
                  <br />
                  <label
                    htmlFor="defaultFormContactEmailEx"
                    className="grey-text"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    id="defaultFormContactEmailEx"
                    className="form-control"
                    name="email"
                  />

                  <br />
                  <label
                    htmlFor="defaultFormContactMessageEx"
                    className="grey-text"
                  >
                    Your message
                  </label>
                  <textarea
                    type="text"
                    id="defaultFormContactMessageEx"
                    className="form-control"
                    rows="3"
                    name="message"
                  />
                  <div className="text-center mt-4">
                    {status === "SUCCESS" ? (
                      <p>Your message has been successfully delivered</p>
                    ) : (
                      <MDBBtn
                        color="warning"
                        outline
                        type="submit"
                        className="btn-send"
                      >
                        Send
                        <MDBIcon far icon="paper-plane" className="ml-2" />
                      </MDBBtn>
                    )}
                    {status === "ERROR" && <p>Oops! There was an error.</p>}
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="1" className="detailed" />
        </MDBRow>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
