import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import "./home.styles.scss";

export const Home = () => {
  return (
    <div>
      <section className="landing">
        <MDBContainer>
          <span className="span" />
          <MDBRow>
            <MDBCol lg="10" md="12">
              <h1 className="hero-text glitch">Hello, I’m Varun Vishal</h1>
              <h2 className="sub-text">
                A &nbsp;
                <span className="inner-span">React Native Developer</span>,
                passionate about creating minimal interfaces.
              </h2>
            </MDBCol>
            <MDBCol lg="2">
              <div className="social-container">
                <h4 className="follow-text">Follow me on</h4>
                <ul class="list-unstyled">
                  <a
                    href="https://github.com/vizvarun"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li title="GitHub">
                      <i class="fab fa-github"></i>
                    </li>
                  </a>
                  <a
                    href="https://linkedin.com/in/vizvarun"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li title="LinkedIn">
                      <i class="fab fa-linkedin"></i>
                    </li>
                  </a>
                  <a
                    href="https://behance.net/vizvarun"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li title="Behance">
                      <i class="fab fa-behance"></i>
                    </li>
                  </a>
                </ul>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </div>
  );
};
