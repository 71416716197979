import React from "react";
import "./client.styles.scss";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 5 };
  }

  render() {
    return (
      <div>
        <section id="client" className="client">
          <div className="divider-block" />
          <MDBContainer>
            <MDBRow className="about-row">
              <MDBCol lg="12" className="skills-text">
                <h4 className="head-text inner-span">Testimonials</h4>
                <h2 className="main-head">My Clients</h2>
              </MDBCol>
            </MDBRow>
            <div className="slider">
              <div className="card-container">
                <MDBRow>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar6.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>
                              Work been done in a professional way. App is
                              working perfectly. Thanks.
                            </h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Md. Abdullah</h3>
                              <h5 className="client-country">USA </h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar5.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>
                              Amazing Developer. Highly recommended for any
                              front-end development project and looking forward
                              for my next project.
                            </h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Manish Jain</h3>
                              <h5 className="client-country">India </h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>
                              "Very good React Native Developer, will work again
                              in the future."
                            </h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Md. Zeeshan</h3>
                              <h5 className="client-country">Saudi Arabia</h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar2.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>Really good at web design.</h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Rajat Ghai</h3>
                              <h5 className="client-country">India </h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar4.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>
                              " I am very happy with the services provided.
                              Varun is knowledgeable and prompt. "
                            </h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Sourav Sen</h3>
                              <h5 className="client-country">India </h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol md="12" lg="6">
                    <MDBCard className="slider-cards">
                      <MDBCardBody className="slider-card-body">
                        <MDBRow className="head-card">
                          <MDBCol lg="12" className="avatar-wrapper">
                            <img
                              src={require("../../assets/images/avatar3.png")}
                              alt="avatar"
                              className="avatar-image"
                            />
                            <ul>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                              <li>
                                <i class="fa fa-star"></i>
                              </li>
                            </ul>
                          </MDBCol>
                          <MDBCol lg="12" className="review">
                            <h3>
                              "Amazing UI skills, and always finishes his work
                              before the deadline."
                            </h3>
                          </MDBCol>
                          <MDBCol lg="12" className="ratings">
                            <div className="client-details">
                              <h3 className="client-name">Ankur Singh</h3>
                              <h5 className="client-country">India </h5>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </div>
            </div>
          </MDBContainer>
        </section>
      </div>
    );
  }
}

export default Clients;
