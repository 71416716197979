import React from "react";
import "./services.styles.scss";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

export const Services = () => {
  return (
    <div>
      <section className="services" id="services">
        <MDBContainer>
          <MDBRow className="services-row">
            <MDBCol lg="6" className="services-text">
              <h4 className="head-text inner-span">What I do ?</h4>
              <h2 className="main-head">My Services</h2>
              <MDBRow>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/mobile-development.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">App Development</h4>
                </MDBCol>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/web-development.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">Web Development</h4>
                </MDBCol>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/ui-designing.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">UI/UX Designing</h4>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol lg="4" className="image-block">
              <img
                className="services-image"
                src={require("../../assets/images/services.gif")}
                alt="profile"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="services-row-small">
            <div className="divider-block" />
            <MDBCol lg="6" className="image-block">
              <img
                className="services-image"
                src={require("../../assets/images/services.gif")}
                alt="profile"
              />
            </MDBCol>
            <MDBCol lg="6" className="services-text">
              <h4 className="head-text inner-span">What I do ?</h4>
              <h2 className="main-head">My Services</h2>
              <MDBRow>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/web-development.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">Web Development</h4>
                </MDBCol>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/mobile-development.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">App Development</h4>
                </MDBCol>
                <MDBCol lg={4} className="service-icon-block">
                  <img
                    src={require("../../assets/images/ui-designing.svg")}
                    alt="web"
                    className="service-icon"
                  />
                  <h4 className="service-name">UI/UX Designing</h4>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </div>
  );
};
