import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import "./portfolio.styles.scss";
import Designs from "../../components/Designs/designs.component";
import WebDevelopment from "../../components/WebDevelopment/web-development.component";
import AppDevelopment from "../../components/AppDevelopment/app-development.component";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="divider-block" />
        <section className="portfolio" id="portfolio">
          <MDBContainer>
            <>
              <MDBRow className="portfolio-row">
                <MDBCol className="portfolio-text">
                  <h4 className="head-text inner-span">Portfolio</h4>
                  <h2 className="main-head">App Development</h2>
                </MDBCol>
              </MDBRow>
              <AppDevelopment />
            </>
            <>
              <MDBRow className="portfolio-row">
                <MDBCol className="portfolio-text">
                  <h4 className="head-text inner-span">Portfolio</h4>
                  <h2 className="main-head">Web Development</h2>
                </MDBCol>
              </MDBRow>
              <WebDevelopment />
            </>
            <>
              <MDBRow className="portfolio-row">
                <MDBCol className="portfolio-text">
                  <h4 className="head-text inner-span">Portfolio</h4>
                  <h2 className="main-head">Designs</h2>
                </MDBCol>
              </MDBRow>
              <Designs />
            </>
          </MDBContainer>
        </section>
      </div>
    );
  }
}

export default Portfolio;
