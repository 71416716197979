import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import "./about.styles.scss";

export const About = () => {
  return (
    <div>
      <section className="about" id="about">
        <MDBContainer>
          <MDBRow className="about-row">
            <MDBCol lg="6" className="image-block">
              <img
                className="profile-image"
                src={require("../../assets/images/profile4.png")}
                alt="profile"
              />
            </MDBCol>
            <MDBCol lg="6" className="about-text">
              <h4 className="head-text inner-span">Who am I ?</h4>
              <h2 className="main-head">About me</h2>
              <div className="profile-block">
                <i class="fas fa-map-marker-alt map-icon"></i>
                <p className="profile-text">
                  &nbsp; Bangalore, India | React Native | React JS | UI/UX
                  Designer
                </p>
              </div>
              <p className="desc-para">
                A Developer and A UI/UX Designer. Interested in coding, but not
                everything about me is designs and code snippets. I have a
                particular proclivity for playing the guitar and various other
                instruments, taking an interest in music composition and
                travelling.
              </p>
              <div class="btn-def resume-btn">
                <a
                  href={require("../../assets/resume.pdf")}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cta"
                >
                  <span>Resume</span>
                  <svg width="13px" height="10px" viewBox="0 0 15 10">
                    <path d="M1,5 L11,5"></path>
                    <polyline points="8 1 12 5 8 9"></polyline>
                  </svg>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </div>
  );
};
