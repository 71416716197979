import React from "react";
import "./navbar.styles.scss";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      labels: [
        {
          title: "About",
          id: 0,
          href: "#about",
        },
        {
          title: "Services",
          id: 1,
          href: "#services",
        },
        {
          title: "Portfolio",
          id: 2,
          href: "#portfolio",
        },
        {
          title: "Skills",
          id: 5,
          href: "#skills",
        },
        {
          title: "Client",
          id: 3,
          href: "#client",
        },
        {
          title: "Contact",
          id: 4,
          href: "#contact",
        },
      ],
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  render() {
    return (
      <div>
        <header>
          <Router>
            <MDBNavbar color="white" light expand="md" fixed="top">
              <MDBNavbarBrand href="/">
                <svg
                  width="35px"
                  height="35px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 140 167"
                >
                  <g fill="#121212">
                    <polygon points="27 0 27 128 0 101 0 0 27 0" />
                    <polygon points="66 0 66 167 65 167 38 139 38 0 66 0" />
                    <polygon points="113 0 113 128 140 101 140 0 113 0" />
                    <polygon points="75 0 75 167 75 167 102 139 102 0 75 0" />
                    <rect
                      x="-9"
                      y="122"
                      width="78"
                      height="8"
                      transform="translate(98 15) rotate(45)"
                    />
                    <rect
                      x="68"
                      y="125"
                      width="78"
                      height="8"
                      transform="translate(274 144) rotate(135)"
                    />
                  </g>
                </svg>
              </MDBNavbarBrand>
              {!this.state.isWideEnough && (
                <MDBNavbarToggler onClick={this.onClick} />
              )}
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav right>
                  {this.state.labels.map((label) => (
                    <MDBNavItem key={label.id}>
                      <a href={label.href}>
                        <div className="nav-link">{label.title}</div>
                      </a>
                    </MDBNavItem>
                  ))}
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          </Router>
        </header>
      </div>
    );
  }
}

export default Navbar;
