import React from "react";
import "./landing-page.styles.scss";
import Navbar from "../../components/Navbar/navbar.component";
import { Home } from "../Home/home.component";
import { About } from "../About/about.component";
import { Services } from "../Services/services.component";
import Portfolio from "../Portfolio/portfolio.component";
import Skills from "../Skills/skills.component";
import Clients from "../Client/client.component";
import Contact from "../Contact/contact.component";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="landing-container">
        <Navbar />
        <div className="landing-page">
          <Home />
          <About />
          <Services />
          <Portfolio />
          <Skills />
          <Clients />
          <Contact />
        </div>
      </div>
    );
  }
}

export default LandingPage;
