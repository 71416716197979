import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

class Designs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      designs: [
        {
          id: 0,
          title: "ScoopBox UI",
          desc: "News App",
          source: require("../../assets/images/ui1.png"),
          href: "https://www.behance.net/gallery/95264701/ScoopBox-News-App",
        },
        {
          id: 1,
          title: "Shoe Store UI",
          desc: "E-commerce Website",
          source: require("../../assets/images/ui2.png"),
          href: "https://www.behance.net/gallery/94228867/E-Commerce-Website-UI",
        },
        {
          id: 2,
          title: "Odyssey UI",
          desc: "Travel App",
          source: require("../../assets/images/ui3.png"),
          href: "https://www.behance.net/gallery/94645719/Odyssey-Online-Travel-Booking-App-UI",
        },
        {
          id: 3,
          title: "Lamborghini Aventador",
          desc: "Detailed Illustration",
          source: require("../../assets/images/ui4.png"),
          href: "https://www.behance.net/gallery/98702507/Lamborghini-Aventador-Illustration",
        },
      ],
    };
  }
  render() {
    return (
      <MDBRow className="project-block">
        {this.state.designs.map((design, id) => (
          <>
            {id % 2 === 0 ? (
              <MDBCol md="12" lg="6">
                <div className="project-n">
                  <div className="project-title" key={design.id}>
                    <h2>{design.title}</h2>
                    <h4>{design.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={design.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div class="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={design.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 15 10"
                              fill="white"
                            >
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            ) : (
              <MDBCol md="12" lg="6">
                <div className="project-n-1">
                  <div className="project-title" key={design.id}>
                    <h2>{design.title}</h2>
                    <h4>{design.desc}</h4>
                    <div className="project-img-wrapper">
                      <img
                        src={design.source}
                        alt="ui"
                        className="project-img"
                      />
                      <div class="overlay">
                        <div class="btn-def view-btn">
                          <a
                            href={design.href}
                            class="cta"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span style={{ color: "white" }}>View</span>
                            <svg width="13px" height="10px" viewBox="0 0 15 10">
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCol>
            )}
          </>
        ))}
      </MDBRow>
    );
  }
}

export default Designs;
