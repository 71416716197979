import React from "react";
import "./skills.styles.scss";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";

class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      design: [
        {
          title: "UX/UI Design",
          id: 0,
        },
        {
          title: "Graphics & Illustrations",
          id: 1,
        },
        {
          title: "Iconography",
          id: 2,
        },
        {
          title: "Typography",
          id: 3,
        },
        {
          title: "Prototyping & Testing",
          id: 4,
        },
      ],

      development: [
        {
          title: "HTML/CSS/JS",
          id: 0,
        },
        {
          title: "React JS",
          id: 1,
        },
        {
          title: "React Native",
          id: 2,
        },
        {
          title: "Gatsby JS",
          id: 3,
        },
        {
          title: "PHP",
          id: 4,
        },
      ],
      tools: [
        {
          title: "VS Code",
          id: 4,
          src: require("../../assets/images/vscode.svg"),
        },
        {
          title: "Figma",
          id: 0,
          src: require("../../assets/images/figma.svg"),
        },
        {
          title: "Adobe Photoshop",
          id: 1,
          src: require("../../assets/images/photoshop.svg"),
        },
        {
          title: "Adobe Illustrator",
          id: 2,
          src: require("../../assets/images/illustrator.svg"),
        },
        {
          title: "Zeplin",
          id: 3,
          src: require("../../assets/images/zeplin.svg"),
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <section id="skills" className="skills">
          <div className="divider-block" />
          <MDBContainer>
            <MDBRow className="about-row">
              <MDBCol lg="12" className="skills-text">
                <h4 className="head-text inner-span">What I Know?</h4>
                <h2 className="main-head">My Skills</h2>
                <MDBRow className="skill-row">
                  <MDBCol lg="4" className="set-row">
                    <ul class="list-column">
                      <h4 class="list-item-h">Development</h4>
                      {this.state.development.map((develop) => (
                        <li class="list-item-c" key={develop.id}>
                          {develop.title}
                        </li>
                      ))}
                    </ul>
                  </MDBCol>
                  <MDBCol lg="4" className="set-row">
                    <ul class="list-column">
                      <h4 class="list-item-h">Design</h4>
                      {this.state.design.map((designs) => (
                        <li class="list-item-c" key={designs.id}>
                          {designs.title}
                        </li>
                      ))}
                    </ul>
                  </MDBCol>
                  <MDBCol lg="4" className="set-row">
                    <ul class="list-column">
                      <h4 class="list-item-h">Tools I Use</h4>
                      {this.state.tools.map((tool) => (
                        <li class="list-item-c" key={tool.id}>
                          <img
                            src={tool.src}
                            alt="logo"
                            className="tool-icon"
                          />
                          <span className="text-1920">&nbsp; {tool.title}</span>
                          <span className="text-991">{tool.title}</span>
                        </li>
                      ))}
                    </ul>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </div>
    );
  }
}

export default Skills;
